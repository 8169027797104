html {
    box-sizing: border-box;
}

body {
    font-family: 'Poppins';
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3 {
    font-style: normal;
    font-family: 'Mulish';
    font-weight: 800;
}

h1 {
    font-size: 64px;
    line-height: 75px;
    margin-bottom: 40px;
}

h2 {
    font-size: 52px;
    line-height: 61px;
    color: #150035;
    margin-bottom: 30px;
}

h3 {
    font-size: 50px;
    line-height: 63px;
    color: #2A2A2A;
}


p,li {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 36px;
    color: #150035;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

b, strong {
    font-weight: 700;
}

img{
    max-width: 100%;
    height: auto;
}
@media (max-width: 868px) {
    h1{
        font-size: 32px;
        line-height: 37px;
        text-align: left!important;
    }
    h2{
        font-size: 28px;
        line-height: 32px;
        text-align: left!important;
    }
    h3{
        font-size: 30px;
        line-height: 38px;  
    }
    p{
        font-size: 17px;
        line-height: 30px;
        text-align: left!important;
    }

}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }

  .site-header .container.domainer::before {
      content: none;
  }


.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 5%;
}

.clearfix {
    clear: both;
    content: '';
}
.text-center{
    text-align: center;
}
.text-white{
    color: #E7E7E7;
}
.main-wrapper{
    position: relative;
    width: 100%;
    max-width: 3000px;
    margin: auto;
}

.scroll-to-link>*, .mobile-nav-button>* {
    pointer-events: none;
  }

.site-header {
    display: block;
    background: linear-gradient(101.17deg, #1D4076 5.19%, #39A1FF 99.43%);
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 15%;
    position: relative;
    overflow: hidden;
}
.site-header:after{
    content: "";
    background-color: #FFFFFF;
    height: 150px;
    left: -10%;
    bottom: -8%;
    position: absolute;
    transform: rotate(-4deg);
    width: 120%;
}

.top-nav,
.elements-right ul.nav-buttons {
    display: flex;
    align-items: center;
}

.elements-right {
    align-items: center;
    display: flex;
    justify-content: center;
}
.elements-right ul.nav-buttons li {
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin-top: 0;
}
.top-nav {
    justify-content: space-between;
    padding: 20px 0;
}
.site-logo {
    font-size: 0;
    position: relative;
    background: url(../image/logo.svg) no-repeat 0 0/contain;
    display: inline-block;
    font-size: 0;
    height: 25px;
    position: relative;
    vertical-align: middle;
    width: 175px;
}

.top-nav .social-buttons {
    display: none;
}
.elements-right ul.nav-buttons li:not(:last-child) {
    margin-right: 72px;
}
.elements-right ul.nav-buttons li a {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    cursor: pointer;
    transition: color 0.25s ease-out, border 0.35s ease-in-out;
    -moz-transition: color 0.25s ease-out, border 0.35s ease-in-out;
    -o-transition: color 0.25s ease-out, border 0.35s ease-in-out;
    -ms-transition: color 0.25s ease-out, border 0.35s ease-in-out;
}
.top-nav ul.nav-buttons li a:hover {
    color: #A4E13F;
}

@media (min-width: 1800px) {
    .site-header {
        padding: 0 15%;
    }
    .site-header:after{
        height: 250px;
        bottom: -20%;
    }
}
@media (max-width: 990px) {
    .elements-right{
        display: none;
    }
}
@media (max-width: 460px) {
    .site-header:after{
        transform: rotate(-13deg);
    }
}


.inner{
    margin: 40px 0;
    background: url(../image/bg-inner.svg) no-repeat 100% 40%/auto;
}
@media (min-width: 1800px) {
    .section-item{
        padding-left: 15%;
        padding-right: 15%;
    }
    .inner{
        margin-bottom: 15%;
        padding: 0 5%;
    }
}
@media (max-width: 868px) {
    .inner{
        background-position: 100% 20%;
        padding-bottom: 40px;
    }
}

.section-item {
    position: relative;
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-flow: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 90px 5%;
    gap: 30px;
}
.image-wrapper .bg-image{
    position: relative;
}
.content-wrapper{
    flex-basis: 50%;
    padding-right: 5%;
}

@media (min-width: 1800px) {
    .section-item{
        padding-left: 15%;
        padding-right: 15%;
    }
}
@media (max-width: 868px) {
    .section-item{
        flex-direction: column;
        padding: 60px 5%;
        overflow-x: clip;
    }
    .image-wrapper .bg-image{
        height: auto;
    }
    .image-wrapper .bg-image img{
        max-width: 330px;
        position: absolute;
        top: -135px;
        z-index: 1;
        left: 0px;
    }
}
.what-is{
    padding-top: 0px; 
    padding-bottom: 0px;
}
.what-is .bg-image{
    top: -120px;
}

@media (max-width: 868px) {
    .what-is{
        padding: 90px 5% 60px 5%;
    }
}

.how-it-works{
    padding-top: 90px;
    padding-bottom: 90px;
    background: linear-gradient(90deg,#39A1FF 70%,#150035 0);
}
.how-it-works .content-wrapper{
    flex-basis: 60%;
}
.future .content-wrapper, .when-to-use .content-wrapper{
   margin-top: 30px;
}

@media (max-width: 868px) {
    .how-it-works{
        background: linear-gradient(360deg,#39A1FF 90%,#150035 0);
    }
    .how-it-works h2{
        width: 70%;
    }
    .how-it-works .content-wrapper{
        margin-top: 20%;
        order: 1;
    }
}

.section-item.when-to-use, .section-item.future{
    padding-right: 0;
    gap: 60px
}
.section-item.when-to-use .left-wrapper,
.section-item.when-to-use .right-wrapper,
.section-item.future .left-wrapper,
.section-item.future .right-wrapper{
    flex-basis: 50%;
    position: relative;
}
.when-to-use .left-wrapper:after,
.section-item.future .left-wrapper:after{
    content: "";
    display: block;
    width: 100%;
    height: 202px;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
}
.when-to-use .left-wrapper:after{
    background-image: url(../image/when-arrow.svg);
}
.future .left-wrapper:after{
    background-image: url(../image/feature-arrow.svg);
}
.when-to-us .content-wrapper, .future .content-wrapper{
    padding-right: 5%;
}
.when-to-use h2, .future h2{
    width: 100%;
    margin-bottom: 20px;
}

.section-item.when-to-use .right-wrapper:before,
.section-item.future .right-wrapper:before{
    content: '';
    position: relative;
    width: 100%;
    height: 267px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
}
.section-item.when-to-use .right-wrapper:before{
    background-image: url(../image/when-bg.webp);
}
.section-item.future .right-wrapper:before{
    background-image: url(../image/feature-bg.webp);
}
.future{
    position: relative;
}
.future:before{
    content: '';
    width: 90%;
    height: 1px;
    background-color: #040404;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
}
@media (min-width: 1800px) {
    .when-to-use h2, .future h2{
        width: 100%;
    }
    .when-to-use .right-wrapper .content-wrapper, 
    .future .right-wrapper .content-wrapper{
        padding-right: 40%;
    }
    .future:before{
        width: 70%;
    }
}

@media (max-width: 868px) {
    .section-item.when-to-use, .section-item.future{
        align-items: flex-start;
        gap: 10px;
        padding: 40px 0 40px 5%;
    }
    .when-to-use .left-wrapper:after, .section-item.future .left-wrapper:after{
        content: none;
    }
    .section-item.when-to-use .right-wrapper:before, .section-item.future .right-wrapper:before{
        height: 134px;
        margin-bottom: 30px;
    }
}

.about{
    background: linear-gradient(90deg,#251341 60%,#150035 0);
    align-items: flex-start;
}
.about .image-wrapper{
    flex-basis: 30%;
}
.btn-wrapper{
    margin-top: 40px;
}
.btn{
    max-width: 323px;
    width: 100%;
    height: 75px;
    line-height: 75px;
    background: #39A1FF;
    font-family: 'Mulish';
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    cursor: pointer;
    position: relative;
    transition: all 0.15s ease-in-out;
}
.btn:hover{
    background: rgba(57, 161, 255, 0.9);
}

@media (max-width: 468px){
    .about {
        background: linear-gradient(360deg,#251341 51%,#150035 0)!important;
    }
}

@media (max-width: 868px) {
    .about {
        background: linear-gradient(360deg,#251341 58%,#150035 0);
    }
    .about h2,  .about p{
        text-align: center!important;
    }
    .btn{
        margin: 0 auto;
    }
    .about .m-hidden{
        display: none;
    }
    .about .m-visible{
        display: block;
        text-align: center;
    }
}

footer .logo-wrapper{
    margin-top: 40px;
    margin-bottom: 15px;
}
footer .site-logo{
    background-image: url(../image/logo-footer.svg);
}
.copyright{
    font-family: 'Mulish';
    font-size: 16px;
    line-height: 23px;
    color: #B1B1B1;
}

/* Read more */
.m-readmore-btn{
    font-size: 17px;
    line-height: 30px;
    text-decoration-line: underline;
    position: relative;
    z-index: 2;
}
.m-visible {
    display: none;
}

.m-hidden {
    display: none;
}

@media (max-width: 868px) {
   
    .m-hidden {
        display: none;
    }

    .m-visible {
        display: block;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }
}